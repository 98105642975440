<template>
  <div>
    <div class="content rounded">
      <div class="text-center flex flex-col items-center">
        <label class="font-semibold text-brand text-lg" for="name">Введите название игры</label>
        <input v-model="gameName" type="text" id="gameName" name="name"
               class="border border-brand mt-2 w-64 h-8 rounded">
      </div>
      <div class="text-center flex flex-col items-center mt-4">
        <label class="font-semibold text-brand text-lg" for="name">Введите название шаблона</label>
        <input v-model="name" type="text" id="name" name="name" class="border border-brand mt-2 w-64 h-8 rounded">
      </div>
      <div class="flex flex-col items-center mt-4">
        <label class="font-semibold text-brand">Состояние игры</label>
        <div class="mt-2">
          <select v-model="system" class="p-1 border-solid border-brand-light border w-64 rounded">
            <option value="MOZGO" class="bg-brand rounded text-white hover:bg-brand-dark">MozgoParty</option>
            <option value="PARTYSTORE" class="bg-brand rounded text-white hover:bg-brand-dark">PartyStore</option>
          </select>
        </div>
      </div>
      <div class="flex justify-center mt-6">
        <button @click="send" class="px-3 py-2 bg-brand text-white mx-4 rounded">
          Подтвердить
        </button>
        <button @click="$emit('close')" class="px-3 py-2 bg-brand text-white mx-4 rounded">
          Отменить
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'

export default {
  name: 'game-copy-modal',
  props: ['id', 'game'],
  data() {
    return {
      name: this.game.template ? this.game.template.name : '',
      gameName: this.game.party ? this.game.party.name : '',
      system: 'MOZGO',
    }
  },
  methods: {
    send() {
      axios.post(`api/games/${this.id}/copy`, {
        name: this.name,
        gameName: this.gameName,
        system: this.system
      }).then((data) => {
        this.$emit('updateData')
      })
    },
  },
}
</script>
<style scoped>
.content {
  background-color: white;
  position: fixed;
  top: 30%;
  left: 35%;
  width: 360px;
  border: 2px solid gray;
  padding-top: 40px;
  padding-bottom: 40px;
}
</style>
