<template>
    <tr class="w-full hover:bg-grey-lightest my-1">
        <td>
            <div class="brand-checkbox my-auto mx-auto">
                <input class="" :checked="checked" type="checkbox" :id="`ch-${game.id}`"/>
                <label :for="`ch-${game.id}`"  @click="$emit('select')"></label>
            </div>
        </td>
        <td v-text="game.id"></td>
        <td v-text="getSystemName(game)"></td>
        <td class="mr-auto" v-text="game.template ? game.template.name : ''"></td>
        <td class="mr-auto" v-text="gameName"></td>
        <td class="mr-auto" v-text="game.city_name"></td>
        <td v-if="game.played_at" class="mr-auto">{{game.played_at | date}}</td>
        <td v-else class="mr-auto"></td>
        <td v-if="game.published_at" class="mr-auto">{{game.published_at | date}}</td>
        <td v-else class="mr-auto">Нет</td>
        <td v-if="$route.query.state === 'failed'">
            <button @click.prevent="changeGame" class="text-white text-xs no-underline py-2 my-auto mx-auto bg-brand rounded hover:bg-brand-light whitespace-no-wrap w-full">
                Повторить генерацию
            </button><button @click.prevent="callMarkedGenerated" class="text-white text-xs no-underline py-2 mt-2 mx-auto bg-brand rounded hover:bg-brand-light whitespace-no-wrap w-full">
                Пометить сгенерированной
            </button>
        </td>
        <td>
            <div class="my-auto mx-auto">
                <router-link :to="{name: 'game', params: {id: game.id}}"  title="Редактировать" class="">
                    <font-awesome-icon class="text-black text-base hover:bg-grey" :icon="['fas', 'edit']"></font-awesome-icon>
                </router-link>
            </div>
        </td>
      <td @click="copy_open = true"><img src="@/icons/copy-regular.svg" class="w-4" alt=""></td>
      <td>
            <a href="" class="text-grey-darkest no-underline hover:text-grey"
               @click.prevent="remove(game.id)">
                <trash></trash>
            </a>
        </td>
        <td class="border-r-0">
            <a href="" class="text-grey-darkest no-underline hover:text-grey"
               @click.prevent="">
                <confirm-modal @updated="$emit('updated')" :itemCheckedAttribute="game.published_at" :itemId="game.id"
                               :baseUrl="baseUrl" :message="stateMessage" :font-awe-icon="'check-circle'"
                               class="flex-1 flex justify-end py-4 text-3xl cursor-pointer onBottomPopper">
                </confirm-modal>
            </a>
        </td>
      <GameCopyModal @close="copy_open = false" v-if="copy_open" :id="this.game.id" :game="game" @updateData="$emit('updated')" />
    </tr>
</template>

<script>
import axios from 'axios'
import swal from 'sweetalert2'
import Trash from '../../icons/trash'
import ConfirmModal from '../../components/popperModals/confirmModal'
import GameCopyModal from '../../pages/games/game/game-copy-modal'

export default {
  components: { ConfirmModal, Trash, GameCopyModal },
  name: 'Game',
  props: ['game', 'checked'],
  data () {
    return {
      data: '',
      on_loading: false,
      error_message: '',
      copy_open: false,
    }
  },
  methods: {
    remove (id) {
      this.$emit('remove', [id])
    },
    changeGame () {
      this.on_loading = true
      axios.get(`api/game/generate/continue?game_id=${this.game.id}&excluded_tours=`).then(({ data }) => {
        this.on_loading = false
        swal('Успех!', 'Игра успешно сгенерирована', 'success').then(() => {
          this.$router.go()
        })
        this.$router.push({ name: 'game', params: { id: data.data.id } })
      }).catch((error) => {
        if (error.response.status === 500) { this.error_message = error.response.data; swal('Ошибка!', this.error_message, 'error') }
      })
    },
    callMarkedGenerated () {
      swal({
        title: "Вы уверены?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Да!",
        cancelButtonText: "Отмена",
      }).then((result) => {
        console.log(result)
            if (result.value) {
              this.markedGenerated()
            }
      });
    },
    markedGenerated () {
      axios.get(`api/game/mark-generated?game_id=${this.game.id}`).then(({ response }) => {
        swal('Успех!', 'Игра успешно сгенерирована', 'success').then(() => {
          this.$router.go()
        })
      }).catch((error) => {
        if (error.response.status === 500) { this.error_message = error.response.data; swal('Ошибка!', this.error_message, 'error') }
      })
    },
    getSystemName (game) {
      if (!game.party) {
        return game.system
      }
      if (game.party.system === 'MOZGO') {
        return 'MozgoParty'
      }
      if (game.party.system === 'PARTYSTORE') {
        return 'PartyStore'
      }
      return '';
   }
  },
  computed: {
    stateMessage () {
      return this.game.published_at ? 'Отменить публикацию?' : 'Опубликовать?'
    },
    baseUrl () {
      return `/api/games/${this.game.id}/publish`
    },
    gameName () {
      if (this.game.party) return this.game.party.name
      if (this.game.type) return this.game.type
      if (this.game.template) return this.game.template.name
      return 'Неизвестная игра'
    },
  },
}

</script>

<style scoped>
    @tailwind utilities;

    td {
        @apply .text-left .p-2 .bg-white .font-sans .text-sm .border-b .border-r .border-grey-light;
    }
</style>
