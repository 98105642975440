<template>
    <on-click-outside :do="close">
        <div class="relative" :class="{ 'is-active': isOpen }">
            <font-awesome-icon ref="button" @click.prevent="switchPopper" :icon="['fas', fontAweIcon]"
                               v-if="itemCheckedAttribute" class="w-6  mx-auto my-auto text-black text-base"></font-awesome-icon>
            <font-awesome-icon ref="button" @click.prevent="switchPopper" :icon="['far', fontAweIcon]"
                               v-else class="w-6 mx-auto my-auto text-black text-base"></font-awesome-icon>
            <div ref="panel" v-show="isOpen" class="z-50 mt-6 ml-4 absolute">
                <div class="modal-container p-4 text-sm w-full flex mx-auto justify-center items-center text-center">
                    <div class="mx-2 my-auto">{{message}}</div>
                    <button @click.prevent="close" class="mx-2 my-auto p-1 bg-brand rounded text-sm text-white w-full mb-1 no-underline">
                        Нет
                    </button>
                    <div @click.prevent="confirmAction" class="mx-2 my-auto p-1 bg-brand-red rounded text-sm text-white w-full mb-1 no-underline">
                        Да
                    </div>
                </div>
            </div>
        </div>
    </on-click-outside>
</template>

<script>
import Popper from 'popper.js'
import OnClickOutside from '../OnClickOutside'
import axios from 'axios'

export default {
  name: 'ConfirmModal',
  components: {
    OnClickOutside,
  },
  props: ['itemCheckedAttribute', 'itemId', 'baseUrl', 'message', 'fontAweIcon'],
  data () {
    return {
      ext: '',
      isOpen: false,
    }
  },
  beforeDestroy () {
    if (this.popper !== undefined) {
      this.popper.destroy()
    }
  },
  methods: {
    switchPopper () {
      this.isOpen = !this.isOpen
      if (this.isOpen) {
        this.$nextTick(() => {
          this.setupPopper()
        })
      }
    },
    close () {
      if (!this.isOpen) {
        return
      }
      this.isOpen = false
    },
    setupPopper () {
      if (this.popper === undefined) {
        this.popper = new Popper(this.$refs.button, this.$refs.panel, {
          placement: 'bottom',
          positionFixed: true,
        })
      } else {
        this.popper.scheduleUpdate()
      }
    },
    confirmAction () {
      axios.patch(this.baseUrl, { state: !this.itemCheckedAttribute })
        .then(() => {
          this.$emit('updated')
        })
      this.close()
    },
  },
}
</script>

<style scoped>

    .modal-container {
        background-color: #fff;
        border-radius: 10px;
        -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
        font-family: Helvetica, Arial, sans-serif;
    }

</style>
